import { deepEqualAtom } from 'src/atoms/utils/deepEqualAtom';

interface SavedMarkers {
  [key: string]: boolean;
}

export const savedMarkersAtom = deepEqualAtom(
  'savedMarkersAtom',
  {} as unknown as SavedMarkers
);
