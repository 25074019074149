import { http } from '@pepita/http';

import type { Listing } from 'src/types/listing-search';
import type { RealEstate, RealEstateKey } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateUserPreferences } from 'src/types/user-preferences';

import { pick } from 'src/utils/object';

export const getListingsUserPreferences = (
  listings: (RealEstate | ListRealEstate | Listing | RealEstateKey)[]
) => {
  const keys = listings.map((item) => pick(item, 'id', 'type'));

  const request = http.get('/api-next/user/preferences/', {
    searchParams: {
      listings: JSON.stringify(keys),
    },
  });

  return request.json<RealEstateUserPreferences[]>();
};

export type SaveListingAction = 'save' | 'unsave';

export const saveListing = async (id: number, action: SaveListingAction) => {
  const url = `/api/utente/annunci/salvati/${id}`;

  if (action === 'save') {
    await http.post(url).json();

    return true;
  }

  await http.delete(url).json();

  return false;
};

export type HideListingAction = 'hide' | 'restore';

export const hideListing = async (id: number, action: HideListingAction) => {
  const url = `/api/utente/annunci/nascosti/${id}`;

  if (action === 'hide') {
    await http.post(url).json();

    return true;
  }

  await http.delete(url).json();

  return false;
};

export const updateListingNote = async (
  listingId: number,
  value: string | null
) => {
  const APIUrl = `/api/utente/annunci/note/${listingId}`;

  if (value) {
    await http
      .post(APIUrl, {
        form: {
          nota: value,
        },
      })
      .raw();
  } else await http.delete(APIUrl).raw();

  return value;
};
